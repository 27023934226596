<template>
    <div class="">
        <!--         <v-card-title class="headline pt-1 pb-5 px-0">Schedules</v-card-title>
 -->
        <div :key="index" v-for="(schedule, index) in schedules">
            <v-flex xs12 class="py-0 px-0">
                <v-layout row>
                    <v-flex xs6>
                        <v-text-field
                            class="mr-1"
                            label="Amount"
                            placeholder="Amount"
                            v-model="schedule.amount"
                            type="number"
                            ref="amount"
                            prefix="$"
                            :rules="[
                                (value) =>
                                    !!value || getAmountValidationMessage(),
                                (value) => {
                                    return (
                                        !value ||
                                        value > 0 ||
                                        getAmountValidationMessage()
                                    );
                                },
                            ]"
                            @input="updateAmount()"
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs6>
                        <v-menu
                            v-model="schedule.datePickerMenu"
                            :close-on-content-click="false"
                            offset-y
                            prepend-icon="mdi-currency-usd"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    color="#4289b0"
                                    v-model="schedule.date"
                                    :value="formatedDate(schedule.date)"
                                    label="Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="schedule.date"
                                :min="date"
                                color="#4289b0"
                                :max="latestDate"
                            ></v-date-picker>
                        </v-menu>
                    </v-flex>
                    <!-- <v-btn @click="addSchedule" flat icon color="success">
                    <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
                <v-btn
                    :disabled="index == 0"
                    @click="removeSchedule(index)"
                    flat
                    icon
                    color="#f55a4e"
                > -->
                    <!-- <v-icon>mdi-minus-circle-outline</v-icon>
                </v-btn> -->
                </v-layout>
            </v-flex>
        </div>

        <!-- <v-card-title v-if="schedulesDiscrepancy" class="pa-2">
            <div class="font-weight-regular" style="color: #D50000">
                Schedule amounts does not match the total
            </div>
        </v-card-title> -->
    </div>
</template>

<script>
import moment from "moment";

export default {
    props: {
        schedules: Array,
        addSchedule: Function,
        removeSchedule: Function,
        schedulesDiscrepancy: Boolean,
        updateAmount: Function,
        latestDate: String,
    },
    data() {
        return {
            date: new Date().toISOString().substr(0, 10),
        };
    },
    created() {},
    computed: {},
    methods: {
        formatedDate(date) {
            return date ? moment(date).format("L") : "";
        },
        getAmountValidationMessage() {
            return "Amount Is Required";
        },
    },
};
</script>
